import React from "react"

import { useSetRecoilState } from "recoil"
import { promModalOpen } from "../../atoms"

export function ActionButtons() {
  const setPromModalOpen = useSetRecoilState(promModalOpen)
  return (
    <div className="grid grid-cols-1 space-y-2 space-x-0 pt-3 sm:pt-12 md:grid-cols-2 md:space-x-2 md:space-y-0">
      <button
        onClick={() => setPromModalOpen(true)}
        className="w-full rounded-full border-0 bg-white py-4 px-4 text-center text-base font-bold text-corp-orange
          transition-colors duration-500 hover:text-orange-600 hover:shadow-xl sm:py-5 sm:px-10 sm:text-2xl"
      >
        Оставить заявку
      </button>
      <button
        onClick={() => setPromModalOpen(true)}
        className="w-full rounded-full border-0 bg-white bg-opacity-30 py-4 px-4 text-center text-base font-bold text-gray-100 backdrop-blur-lg
          backdrop-filter transition-colors duration-500 hover:bg-white hover:text-gray-900 hover:shadow-xl sm:py-5 sm:px-10 sm:text-2xl"
      >
        Написать менеджеру
      </button>
    </div>
  )
}
