// initial data - dynamic data structure works

export const initialState = [
  {
    idx: 1,
    pos: 1,
    text: "1",
    active: true,
    name: "Калуга",
    slug: "kaluga",
    description:
      "Пункт приема, выездные менеджеры, цех переработки, магазин з/ч для выхлопных систем",
    imgAlt: "Калужский филиал КаталикАвто",
    phone: {
      label: "8 (902) 390-37-05",
      local: "89023903705",
      msg: "79023903705",
    },
    address: {
      full: "Калуга, Турынинская улица 8А",
      main: "Калуга, Россия, 248032",
      street: "Турынинская улица, 8А",
      center: [54.515304, 36.34183],
      link: "https://yandex.ru/maps/-/CCUymGdqGC",
    },
    features: [
      "Пункт приема",
      "Выездные менеджеры",
      "Цех переработки",
      "Магазин з/ч для выхлопных систем",
    ],
  },
  {
    idx: 2,
    pos: 2,
    text: "2",
    active: true,
    name: "Тула",
    slug: "tula",
    description: "Пункт приема, выездные менеджеры",
    imgAlt: "Тульский филиал КаталикАвто",
    phone: {
      label: "8 (920) 781-57-73",
      local: "89207815773",
      msg: "79207815773",
    },
    address: {
      full: "Тула, Рязанская улица, 54",
      main: "Тула, Россия, 300045",
      street: "Рязанская улица, 54",
      center: [54.174402, 37.641647],
      link: "https://yandex.ru/maps/-/CCUymGtIDA",
    },
    features: ["Пункт приема", "Выездные менеджеры"],
  },
  {
    idx: 3,
    pos: 3,
    text: "3",
    active: true,
    name: "Смоленск",
    slug: "smolensk",
    description: "Пункт приема, выездные менеджеры",
    imgAlt: "Смоленский филиал КаталикАвто",
    phone: {
      label: "8 (902) 390-37-05",
      local: "89023903705",
      msg: "79023903705",
    },
    address: {
      full: "Смоленск, проспект Гагарина, 26",
      main: "Смоленск, Россия, 214018",
      street: "проспект Гагарина, 26",
      center: [54.766521, 32.044433],
      link: "https://yandex.ru/maps/-/CCUymWtIhA",
    },
    features: ["Пункт приема", "Выездные менеджеры"],
  },
  {
    idx: 4,
    pos: 4,
    active: true,
    name: "Брянск",
    slug: "bryansk",
    description: "Пункт приема, выездные менеджеры",
    imgAlt: "Брянский филиал КаталикАвто",
    phone: {
      label: "8 (902) 390-37-05",
      local: "89023903705",
      msg: "79023903705",
    },
    address: {
      full: "Брянск, Карачижская улица, 103Б",
      main: "Брянск, Россия, 241028",
      street: "Карачижская улица, 103Б",
      center: [53.221543, 34.324699],
      link: "https://yandex.ru/maps/-/CCUymGhktB",
    },
    features: ["Пункт приема", "Выездные менеджеры"],
  },
  {
    idx: 5,
    pos: 5,
    active: true,
    name: "Орёл",
    slug: "orel",
    description: "Пункт приема, выездные менеджеры",
    imgAlt: "Орловский филиал КаталикАвто",
    phone: {
      label: "8 (953) 335-02-22",
      local: "89533350222",
      msg: "79533350222",
    },
    address: {
      full: "Орел, Комсомольская улица, 53",
      main: "Орёл, Россия, 302001",
      street: "Комсомольская улица, 53",
      center: [52.955715, 36.064161],
      link: "https://yandex.ru/maps/-/CCUym0AZgA",
    },
    features: ["Пункт приема", "Выездные менеджеры", "Цех переработки"],
  },
  {
    idx: 6,
    pos: 6,
    active: false,
    name: "Воронеж",
    slug: "voronezh",
    description: "Пункт приема, выездные менеджеры",
    imgAlt: "Воронежский филиал КаталикАвто",
    phone: {
      label: "8 (902) 390-37-05",
      local: "89023903705",
      msg: "79023903705",
    },
    address: {
      full: "Воронеж, Ленинградская улица, 29Г",
      main: "Воронеж, Россия, 394016",
      street: "Ленинградская улица, 29Г",
      center: [51.659729, 39.250409],
      link: "https://yandex.ru/maps/-/CCUymGtb-C",
    },
    features: ["Пункт приема", "Выездные менеджеры"],
  },
  {
    idx: 7,
    pos: 7,
    active: false,
    name: "Белгород",
    slug: "belgorod",
    description: "Пункт приема, выездные менеджеры",
    imgAlt: "Белгородский филиал КаталикАвто",
    phone: {
      label: "8 (902) 696-53-33",
      local: "89026965333",
      msg: "79026965333",
    },
    address: {
      full: "Белгород, улица Серафимовича, 72А",
      main: "Белгород, Россия, 308006",
      street: "улица Серафимовича, 72А",
      center: [50.584667, 36.624494],
      link: "https://yandex.ru/maps/-/CCUymKbKxC",
    },
    features: ["Пункт приема", "Выездные менеджеры"],
  },
  {
    idx: 8,
    pos: 8,
    active: false,
    name: "Курск",
    slug: "kursk",
    description: "Пункт приема, выездные менеджеры",
    imgAlt: "Курский филиал КаталикАвто",
    phone: {
      label: "8 (902) 696-53-33",
      local: "89026965333",
      msg: "79026965333",
    },
    address: {
      full: "Курск, Сосновская улица, 5",
      main: "Курск, Россия, 305000",
      street: "Сосновская улица, 5",
      center: [51.728682, 36.185631],
      link: "https://yandex.ru/maps/-/CCUym0QNGD",
    },
    features: ["Пункт приема", "Выездные менеджеры"],
  },
  {
    idx: 9,
    pos: 9,
    active: false,
    name: "Тверь",
    slug: "tver",
    description: "Пункт приема, выездные менеджеры",
    imgAlt: "Тверской филиал КаталикАвто",
    phone: {
      label: "8 (900) 573-09-95",
      local: "89005730995",
      msg: "79005730995",
    },
    address: {
      full: "Тверь, улица Горького, 35",
      main: "Тверь, Россия, 170026",
      street: "улица Горького, 35",
      center: [56.867213, 35.903434],
      link: "https://yandex.ru/maps/-/CCUym0rkhC",
    },
    features: ["Пункт приема", "Выездные менеджеры"],
  },
  {
    idx: 10,
    pos: 10,
    active: false,
    name: "Саратов",
    slug: "saratov",
    description: "Пункт приема, выездные менеджеры",
    imgAlt: "Саратовский филиал КаталикАвто",
    phone: {
      label: "8 (902) 390-37-05",
      local: "89023903705",
      msg: "79023903705",
    },
    address: {
      full: "Саратов, Вольская улица, 61",
      main: "Саратов, Россия, 410028",
      street: "Вольская улица, 61",
      center: [51.529259, 46.022381],
      link: "https://yandex.ru/maps/-/CCUym0C5tA",
    },
    features: ["Пункт приема", "Выездные менеджеры"],
  },
  {
    idx: 11,
    pos: 11,
    active: false,
    name: "Липецк",
    slug: "lipetsk",
    description: "Пункт приема, выездные менеджеры",
    imgAlt: "Липецкий филиал КаталикАвто",
    phone: {
      label: "8 (902) 390-37-05",
      local: "89023903705",
      msg: "79023903705",
    },
    address: {
      full: "Липецк, Липовская улица, 8",
      main: "Липецк, Россия, 398002",
      street: "Липовская улица, 8",
      center: [52.612014, 39.589949],
      link: "https://yandex.ru/maps/-/CCUym0SAPA",
    },
    features: ["Пункт приема", "Выездные менеджеры"],
  },
  {
    idx: 12,
    pos: 12,
    active: false,
    name: "Иваново",
    slug: "ivanovo",
    description: "Пункт приема, выездные менеджеры",
    imgAlt: "Ивановский филиал КаталикАвто",
    phone: {
      label: "8 (902) 390-37-05",
      local: "89023903705",
      msg: "79023903705",
    },
    address: {
      full: "Иваново, улица Смирнова, 105",
      main: "Иваново, Россия, 153034",
      street: "улица Смирнова, 105",
      center: [56.986011, 41.008084],
      link: "https://yandex.ru/maps/-/CCUymOUaLC",
    },
    features: ["Пункт приема", "Выездные менеджеры"],
  },
  {
    idx: 13,
    pos: 13,
    active: false,
    name: "Волгоград",
    slug: "volgograd",
    description: "Пункт приема, выездные менеджеры",
    imgAlt: "Волгоградский филиал КаталикАвто",
    phone: {
      label: "8 (996) 482-03-34",
      local: "89964820334",
      msg: "79964820334",
    },
    address: {
      full: "Волгоград, Елецкая улица, 173А",
      main: "Волгоград, Россия, 400120",
      street: "Елецкая улица, 173А",
      center: [48.710109, 44.46456],
      link: "https://yandex.ru/maps/-/CCUymPCrGC",
    },
    features: ["Пункт приема", "Выездные менеджеры"],
  },
  {
    idx: 14,
    pos: 14,
    active: false,
    name: "Владимир",
    slug: "vladimir",
    description: "Пункт приема, выездные менеджеры",
    imgAlt: "Владимирский филиал КаталикАвто",
    phone: {
      label: "8 (902) 390-37-05",
      local: "89023903705",
      msg: "79023903705",
    },
    address: {
      full: "Владимир, улица Семашко, 8",
      main: "Владимир, Россия, 600000",
      street: "улица Семашко, 8",
      center: [56.128957, 40.388848],
      link: "https://yandex.ru/maps/-/CCUym0cLxC",
    },
    features: ["Пункт приема", "Выездные менеджеры"],
  },
  {
    idx: 15,
    pos: 15,
    active: false,
    name: "Нижний Новгород",
    slug: "nizhnij-novgorod",
    description: "Пункт приема, выездные менеджеры",
    imgAlt: "Нижегородский филиал КаталикАвто",
    phone: {
      label: "8 (910) 143-44-00",
      local: "89101434400",
      msg: "79101434400",
    },
    address: {
      full: "Нижний Новгород, улица Баженова, 2",
      main: "Нижний Новгород, Россия, 603069",
      street: "улица Баженова, 2",
      center: [56.215618, 43.803264],
      link: "https://yandex.ru/maps/-/CCUBzTWsCA",
    },
    features: ["Пункт приема", "Выездные менеджеры"],
  },
]
