import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

export function Services() {
  return (
    <div className="my-2 px-2 md:px-0 xl:my-8">
      <div
        role="list"
        className="grid justify-items-center gap-x-8 gap-y-4 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2"
      >
        <Link
          to="/"
          className="w-full rounded-md bg-white p-2 shadow-md xl:p-4"
        >
          <div className="flex items-center gap-x-6">
            <StaticImage
              src="../../../assets/images/serebro/ceramic-category.png"
              alt="Автомобильные катализаторы"
              className="h-24 w-24"
            />

            <div className="text-gray-700 hover:text-corp-orange">
              <h4 className="text-base font-medium line-clamp-2 sm:text-2xl">
                Автомобильные катализаторы
              </h4>
              <p className="text-xs">Перейти в раздел {">>"}</p>
            </div>
          </div>
        </Link>

        <Link
          to="/serebro"
          className="w-full rounded-md bg-white p-2 shadow-md xl:p-4"
        >
          <div className="flex items-center gap-x-6">
            <StaticImage
              src="../../../assets/images/services-section/serebro.jpg"
              alt=" Техническое серебро"
              className="h-24 w-24"
            />

            <div className="text-gray-700 hover:text-corp-orange">
              <h4 className="text-base font-medium line-clamp-2 sm:text-2xl">
                Техническое серебро
              </h4>
              <p className="text-xs">Перейти в раздел {">>"}</p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}
