import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { TopSection } from "../components/Prom/TopSection"
import { PricesSection } from "../components/Prom/PricesSection"
import { OnePriceSection } from "../components/Prom/OnePriceSection"
import { ServicesSection } from "../components/Prom/ServicesSection"
import { NewsSection } from "../components/Prom/NewsSection"
import { MapSection } from "../components/Prom/MapSection"
import { HorizontalCard } from "../components/Prom/MapSection/HorizontalCard"

const PromPage = props => {
  const { location } = props
  return (
    <Layout path={location.pathname}>
      {
        // eslint-disable-next-line
        <SEO
          title="Прием промышленных катализаторов в Калуге и по всей России"
          description="Бесплатный выезд специалиста по Центральной России. 
        Мы гарантируем проведение анализа и выплату в день получения отработанного материала. 
        Наша компания скупает промышленные катализаторы по высоким ценам до 75% LME."
        />
      }

      <div className="overflow-hidden bg-gray-100 text-gray-900">
        <TopSection />
        <PricesSection />
        <OnePriceSection />
        <ServicesSection />
        <NewsSection />
        <MapSection>
          <HorizontalCard />
        </MapSection>
      </div>
    </Layout>
  )
}

export default PromPage
