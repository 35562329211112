import React from "react"

import {
  CalendarIcon,
  UserGroupIcon,
  MapIcon,
  ClockIcon,
} from "@heroicons/react/outline"

export function Perks() {
  const perks = [
    {
      title: "7 лет",
      description: " на рынке",
      icon: props => <CalendarIcon {...props} />,
    },
    {
      title: "> 3 тыс.",
      description: "довольных клиентов",
      icon: props => <UserGroupIcon {...props} />,
    },
    {
      title: "15",
      description: "представительств в регионах",
      icon: props => <MapIcon {...props} />,
    },
    {
      title: "> 1000 кг",
      description: "перерабатываем за 2 часа",
      icon: props => <ClockIcon {...props} />,
    },
  ]

  return (
    <div className="bg-transparent pt-4 md:pt-24">
      <div className="sr-only">Наши преимущества</div>
      <div className="mx-auto grid max-w-7xl grid-cols-2 divide-x-0 divide-gray-200 lg:flex lg:justify-center lg:divide-y-0 lg:divide-x lg:py-8">
        {perks.map((perk, index) => (
          <div
            key={`perk-${index}`}
            className="py-2 sm:py-8 lg:w-1/4 lg:flex-none lg:py-0"
          >
            <div className="mx-auto flex max-w-xs items-start px-4 text-gray-100 lg:max-w-none lg:px-6">
              <perk.icon className="h-10 w-10 flex-shrink-0 sm:h-14 sm:w-14" />

              <div className="ml-4 flex flex-auto flex-col content-center">
                <p className="text-lg font-semibold text-gray-100 md:text-2xl">
                  {perk?.title}
                </p>
                <p className="-mt-1 text-xs font-light text-gray-100 md:text-xl">
                  {perk?.description}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
