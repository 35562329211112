import React from "react"
import { CubeTransparentIcon } from "@heroicons/react/outline"

import { useSetRecoilState } from "recoil"
import { promModalOpen } from "../../atoms"

export function Card() {
  const setPromModalOpen = useSetRecoilState(promModalOpen)

  return (
    <div className="mx-auto flex max-w-4xl flex-col items-center justify-between space-y-4 bg-white p-2 text-center md:p-8">
      <CubeTransparentIcon className="h-6 w-6 text-gray-700" />
      <p className="font-light">
        <b>КаталикАвто</b> – ваш надежный партнер в мире промышленных
        катализаторов. Мы занимаемся приобретением катализаторов в форме гранул
        и порошка, оценкой которых мы проводим исходя из содержания драгоценных
        металлов, применяемых для активации химических процессов.
      </p>

      <button
        onClick={() => setPromModalOpen(true)}
        className="rounded-md bg-gray-700 px-16 py-4 text-white shadow transition-colors hover:bg-white hover:text-gray-700"
      >
        Связаться с менеджером
      </button>
    </div>
  )
}
