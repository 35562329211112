import React from "react"
import { Link } from "gatsby"
import { MapIcon } from "@heroicons/react/solid"

export function MapSection({ children }) {
  return (
    <section
      id="location"
      aria-labelledby="map-heading"
      className="mx-auto max-w-xl border-b border-gray-300 bg-gray-100 py-6 px-0 sm:py-20 lg:max-w-7xl"
    >
      <div className="mx-auto max-w-full md:max-w-7xl">
        <h2
          id="map-heading"
          className="text-center text-xl font-bold text-gray-900 sm:text-4xl"
        >
          Пункты приема катализаторов и контакты
        </h2>

        <div className="container mx-auto flex flex-col py-2">{children}</div>

        <div className="flex justify-center pt-2 sm:pt-4">
          <Link
            to="/cities"
            className="inline-flex justify-center rounded-full border border-transparent bg-corp-orange px-4 py-3 text-sm font-medium text-white shadow-sm 
        hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 lg:px-12 lg:text-base"
          >
            Остальные филиалы
            <MapIcon className="ml-2 h-6 w-6" />
          </Link>
        </div>
      </div>
    </section>
  )
}
