import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import {
  FlagIcon,
  ClockIcon,
  DocumentTextIcon,
  IdentificationIcon,
} from "@heroicons/react/outline"

export const GridItem = ({ children, title, description }) => (
  <div className="flex h-28">
    <div className="flex-shrink-0">{children}</div>
    <div className="pl-4">
      <h4 className="text-base font-medium text-gray-900 sm:text-lg">
        {title}
      </h4>
      <p className="pt-1 text-sm text-gray-800 sm:text-base">{description}</p>
    </div>
  </div>
)

export function Grid() {
  return (
    <div className="hidden grid-cols-3 justify-items-center gap-4 pt-16 md:grid">
      <div className="space-y-16">
        <GridItem
          title="Доводим работу до положительного результата"
          description="Утилизируем тонны отходов ежемесячно. Работы будут выполнены максимально оперативно"
        >
          <FlagIcon className="h-12 w-12 text-gray-700 [&>path]:stroke-[1.5]" />
        </GridItem>

        <GridItem
          title="Работы выполняем максимально оперативно"
          description="Используем оборудование для решения Ваших задач"
        >
          <ClockIcon className="h-12 w-12 text-gray-700 [&>path]:stroke-[1.5]" />
        </GridItem>
      </div>

      <StaticImage
        src="../../../assets/images/serebro/kat-1.png"
        alt="Промышленный катализатор"
      />

      <div className="space-y-16">
        <GridItem
          title="Вся необходимая природоохранная документация"
          description="От паспорта отхода до акта об утилизации"
        >
          <DocumentTextIcon className="h-12 w-12 text-gray-700 [&>path]:stroke-[1.5]" />
        </GridItem>

        <GridItem
          title="Гарантия безопасности"
          description="Мы утилизируем промышленные катализаторы сами, на собственном оборудовании"
        >
          <IdentificationIcon className="h-12 w-12 text-gray-700 [&>path]:stroke-[1.5]" />
        </GridItem>
      </div>
    </div>
  )
}
