import React from "react"
import { Link } from "gatsby"

import { news } from "../../News/data"

export function Carrousel() {
  return (
    <div className="container mx-auto w-full flex-grow px-4 py-2 sm:px-0 sm:py-8">
      <div className="mx-auto w-full">
        <div className="container">
          <div
            id="newsScrollContainer"
            className="flex-no-wrap scrolling-touch flex items-start gap-x-2 overflow-x-scroll lg:overflow-x-hidden"
          >
            {[...news]
              .reverse()
              .slice(0, 3)
              .map((article, index) => (
                <Link
                  key={`news-${index}`}
                  to={article.link}
                  className="group w-2/3 flex-none md:w-1/3 md:pb-4"
                >
                  <div className="mx-2 overflow-hidden rounded-lg border bg-white drop-shadow-md group-hover:drop-shadow-lg">
                    <div className="!aspect-w-16 !aspect-h-9 cursor-pointer overflow-hidden">
                      <img
                        src={article?.img}
                        alt={`Фото статьи о ${article?.title}`}
                        className="rounded-t-lg bg-white object-cover duration-500 ease-in-out group-hover:rotate-6 group-hover:scale-125"
                      />
                    </div>
                    <div className="px-4 py-2">
                      <div className="space-y-1">
                        <h4 className="mb-2 pt-1 text-base font-medium text-gray-900 line-clamp-2 sm:text-lg">
                          {article?.title}
                        </h4>
                      </div>
                      <div className="">
                        <p className="text-sm text-gray-600 line-clamp-5 sm:text-base">
                          {article?.paragraphs[0]}
                        </p>
                      </div>
                      <Link
                        to={article.link}
                        className="pt-4 text-sm text-corp-orange hover:text-orange-600 sm:text-base"
                      >
                        Читать далее...
                      </Link>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}
