import React from "react"

import {
  FlagIcon,
  ClockIcon,
  DocumentTextIcon,
  IdentificationIcon,
} from "@heroicons/react/outline"

export function Carrousel() {
  return (
    <div className="container mx-auto block w-full flex-grow px-4 py-8 sm:px-0 md:hidden">
      <div className="mx-auto w-full">
        <div className="container">
          <div
            id="newsScrollContainer"
            className="flex-no-wrap scrolling-touch flex items-start gap-x-2 overflow-x-scroll sm:grid sm:grid-cols-3 lg:overflow-x-hidden"
          >
            <div className="w-2/3 flex-none space-y-2 text-center">
              <FlagIcon className="inline-flex h-10 w-10 items-center text-gray-700 [&>path]:stroke-[1.5]" />
              <h4 className="text-base font-medium text-gray-900 sm:text-lg">
                Доводим работу до положительного результата
              </h4>
              <p className="pt-1 text-sm text-gray-800 sm:text-base">
                Утилизируем тонны отходов ежемесячно. Работы будут выполнены
                максимально оперативно
              </p>
            </div>

            <div className="w-2/3 flex-none space-y-2 text-center">
              <ClockIcon className="inline-flex h-10 w-10 items-center text-gray-700 [&>path]:stroke-[1.5]" />
              <h4 className="text-base font-medium text-gray-900 sm:text-lg">
                Работы выполняем максимально оперативно
              </h4>
              <p className="pt-1 text-sm text-gray-800 sm:text-base">
                Используем оборудование для решения Ваших задач
              </p>
            </div>

            <div className="w-2/3 flex-none space-y-2 text-center">
              <DocumentTextIcon className="inline-flex h-10 w-10 items-center text-gray-700 [&>path]:stroke-[1.5]" />
              <h4 className="text-base font-medium text-gray-900 sm:text-lg">
                Вся необходимая природоохранная документация
              </h4>
              <p className="pt-1 text-sm text-gray-800 sm:text-base">
                От паспорта отхода до акта об утилизации
              </p>
            </div>

            <div className="w-2/3 flex-none space-y-2 text-center">
              <IdentificationIcon className="inline-flex h-10 w-10 items-center text-gray-700 [&>path]:stroke-[1.5]" />
              <h4 className="text-base font-medium text-gray-900 sm:text-lg">
                Гарантия безопасности
              </h4>
              <p className="pt-1 text-sm text-gray-800 sm:text-base">
                Мы утилизируем промышленные катализаторы сами, на собственном
                оборудовании
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
