import { Link } from "gatsby"
import React from "react"
import { Carrousel } from "./Carrousel"

export function NewsSection() {
  return (
    <section id="news" aria-labelledby="news-heading" className="border-y">
      <div className="mx-auto max-w-xl py-6 px-0 sm:py-20 lg:max-w-7xl">
        <h2
          id="news-heading"
          className="text-center text-xl font-bold text-gray-900 sm:text-4xl"
        >
          Полезные статьи и новости
        </h2>
        <h3 className="px-1 pt-2 text-center text-base font-light md:text-2xl">
          Кропотливо подбираем важную информацию по рынку драг металлов
        </h3>

        <Carrousel />

        <div className="flex justify-center">
          <Link
            to="/news"
            className="w-48 rounded-md bg-gray-700 px-1 py-2 text-center text-base text-white hover:bg-gray-600"
          >
            Еще статьи...
          </Link>
        </div>
      </div>
    </section>
  )
}
